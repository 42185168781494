@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

.bg-pattern {
  background-image: url('https://www.transparenttextures.com/patterns/cubes.png');
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-out forwards;
}

.animate-fade-in.delay-1s {
  animation-delay: 1s;
}

.animate-fade-in.delay-2s {
  animation-delay: 2s;
}


.animate-delay-300 {
  animation-delay: 0.3s;
}

.animate-delay-500 {
  animation-delay: 0.5s;
}

